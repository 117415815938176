import React, { useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'modules/modalsSystem/actions/showModal';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';

import './graphMap.scss';
import { Button, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { getMapData } from 'modules/workLook/selectors';
import { MAP_TYPES } from 'modules/modalsSystem/components/modalsWL/MapModal/MapContainer/constants';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';

import {
  ColorComponent,
  HeatComponent,
  MarkerComponent,
  SizeComponent,
} from 'modules/modalsSystem/components/modalsWL/MapModal/MapContainer/components';
import { SET_MAP_DATA } from 'modules/workLook/actions';
import { isEmpty, find } from 'lodash';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 16px',
    borderRadius: '16px',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.grey[200],
    marginBottom: '10px',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const GraphMap = ({
  variant = 'contained',
  data,
  isPdf = false,
  typeOfMap,
  isLoading,
  title,
  subtitle,
  graphId,
  typeOfGroup,
  isPublished,
  isModal,
  showTitle,
  type,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Data being passed to SET_MAP_DATA: ', data);
    dispatch(SET_MAP_DATA(data));
  }, [data, dispatch]);

  const { mapType, mapCenter, mapZoom } = useSelector(getMapData);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const classes = useStyles();

  const dataValue = data?.value;
  const getTypeData = Array.isArray(data);

  const buildings = isEmpty(data) || getTypeData ? [] : find(data, (el) => Array.isArray(el));
  const maxIntensity = Math.max(...buildings?.map((building) => building[dataValue]));

  const RENDER_MAP = {
    [MAP_TYPES.STANDARD]: <MarkerComponent data={buildings} fitBounds={!(mapZoom && mapCenter)} />,
    [MAP_TYPES.SIZE]: <SizeComponent data={buildings} maxValue={maxIntensity} dataValue={dataValue} />,
    [MAP_TYPES.HEAT]: <HeatComponent data={buildings} maxIntensity={maxIntensity} dataValue={dataValue} />,
    [MAP_TYPES.COLOR]: <ColorComponent data={buildings} />,
  };

  const [firstBuilding] = buildings;

  const onClick = () => dispatch(showModal(modalCodes.WL_MAP_MODAL));

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="mapContainer" elevation={2}>
          <GraphHeader
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <div className="mapContainer">
          {!isPdf && (
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <If condition={!getTypeData}>
                <Button variant={variant} classes={{ root: classes.root }} onClick={onClick}>
                  Select map type
                </Button>
              </If>
              <GraphHeader
                graphId={graphId}
                isPdf={isPdf}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
                isPublished={isPublished}
              />
            </div>
          )}
          <MapContainer zoomControl={!isPdf} center={mapCenter} zoom={mapZoom} scrollWheelZoom={!isPdf}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {isEmpty(data) || getTypeData ? null : RENDER_MAP[mapType || typeOfMap]}
          </MapContainer>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default GraphMap;
