/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Loader } from 'components';
import { ThemeProvider } from '@mui/material';
import theme from './modifierSettings.module.scss';
import ModifierItem from './components/modifier-item';
import ColorForm from './components/createModifierForms/ColorForm';
import SortingForm from './components/createModifierForms/SortingForm';
import DataModifierForm from './components/createModifierForms/DataModifierForm';
import DifferenceForm from './components/createModifierForms/DifferenceForm/DifferenceForm';
import FilterForm from './components/createModifierForms/FilterForm';
import PeakValueForm from './components/createModifierForms/PeakValueForm';
import CombineSingleValueForm from './components/createModifierForms/CombineSingleValue';
import MergeForm from './components/createModifierForms/MergeForm';
import MapMarker from './components/createModifierForms/MapMarker';
import MapSaveStateForm from './components/createModifierForms/MapSaveStateForm';
import CombineMultipleValueForm from './components/createModifierForms/CombineMultipleValue';
import { selectStyles } from './components/createModifierForms/select.styles';

const ModifierSettings = ({
  isLoading,
  getTabModifiersList,
  onCreateModifierClick,
  onEditModifier,
  activeModifier,
  isModifierLoading,
  modifierData,
  modifierFields,
  seriesList,
  activeTab,
  groupType,
  graphId,
  legends,
  onClose,
  onCancelModifier,
  modifierGroupFields,
}) => {
  const displaySeriesForm = activeModifier.displayModifier;
  const getActiveModifier = activeModifier.modifierId;
  const getModifierType = activeModifier.type;
  const getModifierAction = activeModifier.action;

  const props = {
    modifierFields,
    legends,
    seriesList,
    graphId,
    modifierData,
    onClose,
    modifierType: getModifierType,
    modifierId: getActiveModifier,
    modifierAction: getModifierAction,
    isModifierLoading,
    onCancelModifier,
    modifierGroupFields,
    groupType,
  };

  return (
    <Choose>
      <When condition={isLoading}>
        <Loader />
      </When>
      <When condition={!isLoading}>
        <div className={theme.container}>
          <Typography variant="h6" className={theme.title}>
            List of Modifiers
          </Typography>
          <div className={theme.seriesBody}>
            <div className={theme.seriesContainer}>
              <Button fullWidth onClick={() => onCreateModifierClick()} color="primary" variant="outlined">
                Create Modifier
              </Button>
              {getTabModifiersList.map(({ id, name }, index) => (
                <ModifierItem onEditModifier={onEditModifier} key={id} modifierId={id} name={name} index={index} />
              ))}
            </div>
            <If condition={displaySeriesForm}>
              <ThemeProvider theme={selectStyles}>
                <div className={theme.seriesFormContainer}>
                  <If condition={activeTab === 'difference'}>
                    <DifferenceForm {...props} />
                  </If>
                  <If condition={activeTab === 'data'}>
                    <DataModifierForm {...props} />
                  </If>
                  <If condition={activeTab === 'color'}>
                    <ColorForm {...props} />
                  </If>
                  <If condition={activeTab === 'order'}>
                    <SortingForm {...props} />
                  </If>
                  <If condition={activeTab === 'filter'}>
                    <FilterForm {...props} />
                  </If>
                  <If condition={activeTab === 'peak_value'}>
                    <PeakValueForm {...props} />
                  </If>
                  <If condition={activeTab === 'combine'}>
                    <CombineSingleValueForm {...props} />
                  </If>
                  <If condition={activeTab === 'merge'}>
                    <MergeForm {...props} />
                  </If>
                  <If condition={activeTab === 'map_marker'}>
                    <MapMarker {...props} />
                  </If>
                  <If condition={activeTab === 'map_save_state'}>
                    <MapSaveStateForm {...props} />
                  </If>
                  <If condition={activeTab === 'combine_m'}>
                    <CombineMultipleValueForm {...props} />
                  </If>
                </div>
              </ThemeProvider>
            </If>
          </div>
        </div>
      </When>
    </Choose>
  );
};

export default ModifierSettings;
