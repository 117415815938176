import { useMap } from 'react-leaflet';
import { useEffect } from 'react';

const useMapStartZoom = (data) => {
  const map = useMap();

  useEffect(() => {
    if (data && data.length > 0) {
      const coordinatesArray = data.map(({ coordinates }) => coordinates);

      const latitudesArray = coordinatesArray.map(([longitude, latitude]) => latitude);
      const longitudesArray = coordinatesArray.map(([longitude, latitude]) => longitude);

      const ne = [Math.max(...longitudesArray), Math.max(...latitudesArray)];
      const sw = [Math.min(...longitudesArray), Math.min(...latitudesArray)];

      map.fitBounds([sw, ne]);
    }
  }, [data, map]);
};

export default useMapStartZoom;
