const getMapData = ({ workLook }) => ({
  mapData: workLook.mapTypes.mapData,
  maxIntensity: workLook.mapTypes.maxIntensity,
  mapType: workLook.mapTypes.mapType,
  mapDataValue: workLook.mapTypes.mapDataValue,
  mapCenter: workLook.mapTypes.mapCenter, // Add this line
  mapZoom: workLook.mapTypes.mapZoom, // Add this line
});

export default getMapData;
