/* eslint-disable react/no-children-prop */
import React, { useCallback, useState } from 'react';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  Box,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getMapData } from 'modules/workLook/selectors';
import { MAP_TYPES } from 'modules/modalsSystem/components/modalsWL/MapModal/MapContainer/constants';
import {
  ColorComponent,
  HeatComponent,
  MarkerComponent,
  SizeComponent,
} from 'modules/modalsSystem/components/modalsWL/MapModal/MapContainer/components';
import { Skeleton } from '@material-ui/lab';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ENDPOINTS } from 'modules/shared/constants';
import { isLogged } from 'utils';
import axios from 'axios';
import theme from './mapSaveStateForm.module.scss';

const MapSaveStateForm = ({
  modifierType,
  seriesList,
  modifierId,
  modifierAction,
  onClose,
  graphId,
  isModifierLoading,
  modifierData,
  onCancelModifier,
}) => {
  // Map data
  const {
    mapData,
    maxIntensity,
    mapDataValue,
    mapType: Type,
    mapCenter: Center,
    mapZoom: Zoom,
  } = useSelector(getMapData);
  const [mapType, setMapType] = useState(Type);
  const [mapCenter, setMapCenter] = useState(
    Center === null ? { lat: mapData[0].coordinates[0], lng: mapData[0].coordinates[1] } : Center,
  );
  const [mapZoom, setMapZoom] = useState(Zoom);

  if (Array.isArray(mapCenter)) {
    setMapCenter({ lat: mapCenter[0], lng: mapCenter[1] });
  }

  const handleChange = (event) => {
    setMapType(event.target.value);
  };

  const RENDER_MARKER = {
    [MAP_TYPES.STANDARD]: <MarkerComponent data={mapData} fitBounds={!(Center && Zoom)} />,
    [MAP_TYPES.SIZE]: (
      <SizeComponent data={mapData} maxValue={maxIntensity} dataValue={mapDataValue} fitBounds={!(Center && Zoom)} />
    ),
    [MAP_TYPES.HEAT]: (
      <HeatComponent
        data={mapData}
        maxIntensity={maxIntensity}
        dataValue={mapDataValue}
        fitBounds={!(Center && Zoom)}
      />
    ),
    [MAP_TYPES.COLOR]: <ColorComponent data={mapData} fitBounds={!(Center && Zoom)} />,
  };

  // Modifier data

  const { name, graph, is_active, series, order, action } = modifierData || {};
  const isEditMode = modifierType === 'edit';
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    order: Yup.string().required('*Order required'),
    series: Yup.array().min(1, '*Series required'),
  });

  const initialCreateValues = {
    graph: graphId,
    name: '',
    order: '',
    action: modifierAction,
    is_active: true,
    series: [],
  };

  const editInitialValues = {
    graph,
    name,
    order,
    action,
    is_active,
    series,
  };

  const updateMapState = useCallback(
    (newCenter, newZoom) => {
      if (newCenter && (newCenter.lat !== mapCenter.lat || newCenter.lng !== mapCenter.lng)) {
        setMapCenter(newCenter);
      }
      if (newZoom !== mapZoom) {
        setMapZoom(newZoom);
      }
    },
    [mapCenter, mapZoom],
  );

  const MapEvents = () => {
    const map = useMapEvents({
      zoomend: () => {
        updateMapState(map.getCenter(), map.getZoom());
      },
      moveend: () => {
        updateMapState(map.getCenter(), map.getZoom());
      },
      load: () => {
        console.log('Map loaded');
        updateMapState(map.getCenter(), map.getZoom());
      },
    });
    return null;
  };

  const formik = useFormik({
    enableReinitialize: true,
    // Use 'initialValues' instead of 'initialCreateValues'
    initialValues: isEditMode ? editInitialValues : initialCreateValues,
    validationSchema,
    onSubmit: (values) => {
      const getValues = Object.assign(values, {
        modifier_settings: {
          map_state: {
            type: mapType,
            zoom: mapZoom,
            center: [mapCenter.lat, mapCenter.lng],
          },
        },
      });
      const result = JSON.stringify(getValues, (k, val) => (val === '' ? null : val));
      const urlEditModifier = ENDPOINTS.workLook.crudModifier.replace(':modifierId', modifierId);
      const url = ENDPOINTS.workLook.modifiersList.replace(':graphId', graphId);
      const config = {
        method: isEditMode ? 'put' : 'post',
        url: isEditMode ? urlEditModifier : url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({ isError: true, errorMsg: error?.response?.data });
        });
    },
  });

  return (
    <Choose>
      <When condition={isModifierLoading}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </When>
      <Otherwise>
        <div className={theme.container}>
          <div className={theme.header}>
            <Typography variant="h5">
              {isEditMode ? 'Edit Map Default State Modifier' : 'Create Map Default State Modifier'}
            </Typography>
          </div>

          <div className={theme.mapContain}>
            <MapContainer center={Center === null ? mapData[0].coordinates : Center} zoom={Zoom} scrollWheelZoom>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {RENDER_MARKER[mapType]}
              <MapEvents />
            </MapContainer>
          </div>
          <Box sx={{ mt: 2, mb: 2 }}>
            <div
              style={{
                height: '95%',
                marginLeft: '30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '35%',
              }}
            >
              <FormControl>
                <FormLabel focused={false} id="mapType">
                  Choose map type:
                </FormLabel>
                <RadioGroup aria-labelledby="mapType" name="controlled-mapType" value={mapType} onChange={handleChange}>
                  <FormControlLabel value={MAP_TYPES.STANDARD} control={<Radio color="primary" />} label="Standard" />
                  <FormControlLabel value={MAP_TYPES.HEAT} control={<Radio color="primary" />} label="Heat" />
                  <FormControlLabel value={MAP_TYPES.SIZE} control={<Radio color="primary" />} label="Size" />
                  <FormControlLabel value={MAP_TYPES.COLOR} control={<Radio color="primary" />} label="Color" />
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
          <div className={theme.formWrapper}>
            <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <If condition={errors.isError}>
                  {errors.errorMsg.map((msg) => (
                    <Typography variant="body2" className={theme.error}>
                      {msg}
                    </Typography>
                  ))}
                </If>
              </Box>
              <div>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter series name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.name}
                </Typography>
              </div>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_active"
                      name="is_active"
                      color="primary"
                      checked={formik.values.is_active}
                      value={formik.values.is_active}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active Modifier"
                />
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="series">Series</InputLabel>
                  <Select
                    labelId="series"
                    id="series"
                    name="series"
                    label="Series"
                    multiple
                    value={formik.values.series}
                    onChange={formik.handleChange}
                  >
                    {seriesList.map(({ id, name: seriesName }) => (
                      <MenuItem key={id} value={id}>
                        {seriesName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.series}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  className={theme.input}
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.order}
                </Typography>
              </Box>
              <div className={theme.btnContainer}>
                <Button onClick={() => onCancelModifier()} className={theme.cancelBtn} variant="outlined">
                  Cancel
                </Button>
                <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default MapSaveStateForm;
