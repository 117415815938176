import { createReducer } from '@reduxjs/toolkit';
import { MAP_TYPES } from 'modules/modalsSystem/components/modalsWL/MapModal/MapContainer/constants';
import { SET_MAP_TYPE, SET_MAP_DATA } from 'modules/workLook/actions';

const initState = {
  mapType: MAP_TYPES.STANDARD,
  mapData: [],
  maxIntensity: 0,
  mapCenter: null, // Example default value
  mapZoom: 10, // Example default value
};

export default createReducer(initState, {
  [SET_MAP_TYPE]: (state, { payload }) => ({
    ...state,
    mapType: payload,
  }),
  [SET_MAP_DATA]: (state, { payload }) => {
    const mapState = payload.map_state || {}; // Safely access map_state
    return {
      ...state,
      mapType: mapState.type || state.mapType, // Use existing state as fallback
      mapData: payload.buildings,
      maxIntensity: payload.max_intensity,
      mapDataValue: payload.value,
      mapCenter: mapState.center || state.mapCenter, // Use existing state as fallback
      mapZoom: mapState.zoom || state.mapZoom, // Use existing state as fallback
    };
  },
});
